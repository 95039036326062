<template>
  <a-modal :visible="visible" :wrap-class-name="$style.modalSync" :closable="false">
    <p class="mt-2">
      Export Excel
    </p>
    <div>
      <label>Tanggal Pemesanan</label>
      <a-range-picker
        class="date-container w-100"
        :ranges="ranges"
        :format="dateFormat"
        :value="[startDate, endDate]"
        :placeholder="[
          $t('dashboardContent.start_date'),
          $t('dashboardContent.end_date'),
        ]"
        :disabled-date="disabledDate"
        :disabled="isLoading"
        allow-clear
        @change="onChangeDate"
      >
        <DateIcon slot="suffixIcon" style="color: #999999" />
      </a-range-picker>
    </div>
    <div class="mt-3">
      <label>Movement Type</label>
      <a-select 
        v-model="selectedMovementType" 
        allow-clear
        :max-tag-count="1"
        show-search
        mode="multiple"
        class="w-100 select-antd-default" 
        option-filter-prop="children"
        :filter-option="filterOption"
        placeholder="Pilih Movement Type"
        :disabled="isLoading"
        @select="onSelectStateMovement"
        @deselect="onDeselectStateMovement"
      >
        <a-select-option v-for="(item, index) in movementStatus" :key="index" :value="item.value">
          {{ item.label }}
        </a-select-option>
      </a-select>
    </div>
    <template #footer>
      <a-button key="back" :disabled="isLoading || isCalculatingData" class="mr-2" @click="typeof back !== 'undefined' && back(), resetData(), onClose()">
        Cancel
      </a-button>
      <a-button key="submit" :disabled="isLoading || !startDate || isCalculatingData" type="primary" @click="onSubmitModal()">
        Export Excel
      </a-button>
    </template>
  </a-modal>
</template>

<script>
import getQueryParam from '@/utils/getQueryParam'
import DateIcon from '@/components/Icons/DateV2.vue'
import { getDetailStockMovementBySKU } from '@/api/inventory'

export default {
  name: 'ModalExportExcel',
  components: {
    DateIcon,
  },
  mixins: [getQueryParam],
  props: {
    visible: Boolean,
    back: Function,
    onClose: Function,
    isLoading: Boolean,
    movementStatus: Array,
    catalogId: String,
  },
  setup() {
  },
  data() {
    return {
      ranges: {
        'Hari ini': [this.$moment(), this.$moment()],
        'Bulan ini': [this.$moment().startOf('month'), this.$moment().endOf('month')],
        'Tahun ini': [this.$moment().startOf('year'), this.$moment().endOf('year')],
        '7 Hari Terakhir': [this.$moment().subtract(7, 'days'), this.$moment()],
        '30 Hari Terakhir': [this.$moment().subtract(1, 'month'), this.$moment()],
        '1 Tahun Terakhir': [this.$moment().subtract(1, 'year'), this.$moment()],
      },
      dateFormat: 'DD MMMM YYYY',
      startDate: '',
      endDate: '',
      selectedMovementType: [],
      totalRowData: 0,
      isCalculatingData: false,
    }
  },
  computed: {
    integration() {
      return this.$store.state.integration
    },
  },
  watch: {
    'selectedMovementType': {
      deep: true,
      immediate: true,
      handler: function () {
        if (this.selectedMovementType && this.startDate && this.endDate) {
          this.onCalculateRowData()
        }
      },
    },
    'startDate': {
      deep: true,
      immediate: true,
      handler: function () {
        if (this.selectedMovementType && this.startDate && this.endDate) {
          this.onCalculateRowData()
        }
      },
    },
    'endDate': {
      deep: true,
      immediate: true,
      handler: function () {
        if (this.selectedMovementType && this.startDate && this.endDate) {
          this.onCalculateRowData()
        }
      },
    },
  },
  methods: {
    to(url) {
      const route = typeof url === 'object' ? url : { path: url }
      route.query = {
        ...this.to.query,
        ...this.getQueryParam({ ...this.$route.query }),
        ...url.params?.query,
      }
      
      return route
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().includes(input.toLowerCase())
      )
    },
    onSelectStateMovement(record) {
      if (record === 'ALL') {
        this.selectedMovementType = this.movementStatus.map((obj) => obj.value)
      }
    },
    async onDeselectStateMovement(record) {
      if (record === 'ALL') {
        this.selectedMovementType = await []
      } else if (record !== 'ALL' && this.selectedMovementType.includes('ALL')) {
        const deselectAll = await this.selectedMovementType
        this.selectedMovementType = deselectAll.filter((obj) => !['ALL', record].includes(obj))
      }
    },
    disabledDate(current) {
      return current && current > this.$moment().endOf('day')
    },
    onChangeDate(date) {
      this.startDate = date[0]
        ? this.$moment(date[0]).format(this.formatDate)
        : ''
      this.endDate = date[1]
        ? this.$moment(date[1]).format(this.formatDate)
        : ''
    },
    onSubmitModal() {
      if (this.totalRowData === 0) {
        this.$notification.error({
          message: 'Tidak ada data berdasarkan filter yang dipilih.',
        })
      } else {
        let movementType = ''
        if (this.selectedMovementType?.length > 0 && !this.selectedMovementType?.includes('ALL')) {
          movementType = this.selectedMovementType.join(',')
        } else {
          movementType = undefined
        }
        const selectedData = {
          startDate: this.startDate,
          endDate: this.endDate,
          selectedMovementType: movementType,
          totalData: this.totalRowData,
        }
        this.$emit('handleSubmitModal', selectedData)
        this.resetData()
      }
    },
    resetData() {
      this.startDate = ''
      this.endDate = ''
      this.selectedMovementType = []
      this.totalRowData = 0
    },
    async onCalculateRowData() {
      this.isCalculatingData = true
      let movementType = this.selectedMovementType.join(',')
      const payload = {
        warehouse_id: this.$route.query.warehouse_id,
        catalog_id: this.catalogId,
        page: 1,
        size: 1,
        periode_awal: this.$moment(this.startDate).startOf('day').format(),
        periode_akhir: this.$moment(this.endDate).endOf('day').format(),
        movement_type: movementType,
      }
      const { data } = await getDetailStockMovementBySKU(payload)
      this.totalRowData = data.total_row || 0
      this.isCalculatingData = false
    },
  },
}
</script>

<style lang="scss" module>
// @import './style.module.scss';
</style>